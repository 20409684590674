<template>
  <div class="p-fluid function-view">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="row manager-title-row">
          <div class="p-col-4 manager-title-custom">{{ title }}</div>
          <div class="p-col-8 txt-right">
            <Toolbar class="fixed-bottom-toolbar bg-transparent border-0">
              <template slot="right">
                <Button
                  label="Quay lại"
                  @click="backToList()"
                  class="p-button-warning callback-btn"
                />
                <Button
                  v-if="mode == 'edit'"
                  :label="'Lưu'"
                  @click="triggerSave = true"
                  class="p-button-success main-style-button"
                />
                <Button
                  v-else
                  :label="'Thêm mới'"
                  @click="triggerSave = true"
                  class="p-button-success main-style-button"
                />
              </template>
            </Toolbar>
          </div>
        </div>
        <!-- <div class="note">(*) Nội dung không được bỏ trống</div> -->
        <formEdit
          :key="componentKey"
          :mode="mode"
          @back="backToList"
          @save="saveData"
          :triggerSave.sync="triggerSave"
          :model="models"/>
      </div>
    </div>
  </div>
</template>

<script>
// import Config from '../../../config/index'
import DataServices from '@/core/DataServices'
import formEdit from './add'
import moment from "moment-timezone";
// import gql from "graphql-tag";
export default {
  components: {
    formEdit
  },
  data() {
    return {
      users: null,
      validationErrors: [],
      title: "Tạo câu hỏi thêm",
      mode: 'add',
      models: {},
      componentKey: 0,
      accountID: '',
      page_transaction:null,
      active_check:true,
      users_clubs_id : '',
      triggerSave: false,
      time_zone_local : moment.tz.guess(true),
      type_service_id: '',
      icon_service: []
    }
  },
  async created() {
    await this.fetchTypeService();
  },
  async mounted () {
    console.log("this.$route.name:",this.$route.query.page_transaction);
    this.mode = this.$route.name === 'editQuestion' ? 'edit' : this.mode;
    this.title = this.mode === 'edit' ? 'Sửa câu hỏi thêm' : this.title;
    if (this.$route.params.id !== undefined) await this.getData(this.$route.params.id);
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
    //check quyền
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_QUESTION);
    if (this.mode == 'edit'){
      if (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }else {
      if (arr_role.indexOf(this.$constants.ROLE.ADD) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }
  },
  methods: {
    async fetchTypeService() {
      var where_key = {
          type: {_eq: 'beauty'}
      }
      let {data} = await this.$apollo.query({
          query: DataServices.getList('type_service', {"fields" : `id name`}),
          variables: {
            where_key: where_key,
            orderBy:[{ordinal : "asc" }]
          },
          fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      this.type_service_id = list[0].id;
    },
    backToList() {
      this.$router.push({ path: '/admin/list-question' });
    },
    
    async saveData(params) {
      console.log("params",params)
      let objects = {
        'name' : params.name,
        'plain_name': params.plain_name,
        'ordinal': params.ordinal,
        'updated_at': moment(new Date())
      };
      var services = params.services
      this.triggerSave = false;
      if(this.mode == 'add'){
        console.log("ADD")
        await this.$apollo.mutate({
            mutation: DataServices.insertData('questions'),
            variables: {
              objects: objects
            }
        }).then(async (res) => {
            if (res.data[Object.keys(res.data)].affected_rows) {
              var returning = res.data[Object.keys(res.data)].returning[0];
                // this.redirectEdit();
              var servicesData = []
              for (let i=0; i < services.length; i++) {
                  servicesData.push({
                      'question_id': returning.id,
                      'type_service_id': services[i].id
                  });
              }
              let variables = {
                  objects: servicesData
              };
              await this.$apollo.mutate({
                  mutation: DataServices.insertData('question_service'),
                  variables: variables
              }).then(() => {
                  this.redirectEdit();
              }).catch(async error => {
                console.log('error', error);
                this.$toast.add({
                    severity: 'error',
                    summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                    detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                    life: 3000
                });
                this.$commonFuction.is_loading(false);
            });
          }
        })
      }else{
        var id = this.$route.params.id;
        let variables = {
          'data_update': objects,
          'where_key': {
            'id': {'_eq': id}
          }
        }
        await this.$apollo.mutate({
            mutation: DataServices.updateData('questions'),
            variables: variables
        }).then(async (res) => {
            if (res.data[Object.keys(res.data)].affected_rows) {
              var returning = res.data[Object.keys(res.data)].returning[0];
               await this.$apollo.mutate({
                    mutation: DataServices.deleteData('question_service'),
                    variables: {
                        'where_key': {
                            'question_id': {'_eq': id}
                        }
                    }
                })
                
                var servicesData = []
                for (let i=0; i < services.length; i++) {
                    servicesData.push({
                        'question_id': returning.id,
                        'type_service_id': services[i].id
                    });
                }
                let variables = {
                    objects: servicesData
                };
                await this.$apollo.mutate({
                    mutation: DataServices.insertData('question_service'),
                    variables: variables
                }).then(() => {
                    this.redirectEdit();
                }).catch(async error => {
                  console.log('error', error);
                  this.$toast.add({
                      severity: 'error',
                      summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                      detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                      life: 3000
                  });
                  this.$commonFuction.is_loading(false);
              });
            }
        })
      }
      this.$commonFuction.is_loading(false);
    },
    async redirectEdit() {
      var that = this;
      var router_back = {path: '/admin/list-question'};
      that.$commonFuction.is_loading(false);
      if(that.mode == 'add'){
        that.$router.push(router_back);
      }else{
        if (!that.$commonFuction.isEmpty(router_back)){
          // that.page_transaction = ""+that.page_transaction
          router_back.query = { page_transaction: that.page_transaction.toString() };
        }
        that.$router.push(router_back);
      }
      that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Save data successfully', life: 3000 })
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('questions', {"fields" : "id name ordinal question_services {type_service {id name type}}"}),
        variables: {
          where_key: {
            id: {'_eq': id},
            deleted: {_eq: false}
          }
        },
        fetchPolicy: "network-only"
      })
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
        this.name = this.models.name;
        this.ordinal = this.models.ordinal;
        // for(let i =0; i < this.models.question_services.length; i++) {
        //   this.selTypeService.push({id: this.models.question_services[i].type_service.id, name: this.models.question_services[i].type_service.name})
        // }
        this.componentKey++;
      }
    }
  },
  apollo: {
    
  }
}
</script>

<style lang="scss">
  .manager-title-custom{
    color: #1D3118;
    font-size: 18px;
    /* margin-bottom: 16px; */
    padding-bottom: 10px;
    /* border-bottom: 1px solid #EEEEEE; */
    font-weight: 700;
  }
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.note{ letter-spacing: 0px; color: #707070; margin-bottom: 24px; padding-top: 10px;}
.p-formgrid { margin: 0;
  .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
  .p-card-field{ padding: 24px;
    .p-field{
      .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
      .p-col-width{width:500px !important; border-radius: 5px;
        &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px;}
        .file-select{margin: 0;
          input[type="file"]{display: none;}
          .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
        }
        &.pac-target-input{ height: 80px;  word-break: break-all; overflow-wrap: break-word;  resize: none;}
      }
      .box-images{ margin: 0; margin-top: 10px; position: relative;
        .item-img{width: 150px; height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative;
          img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain; padding: 5px;}
          .button-clear{border-radius: 10px; width: 24px; height: 24px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;color: #ffffff;
            img{vertical-align: top;}
          }
        }
      }
      .p-invalid{display: block;}
    }
  }
  .fixed-bottom-toolbar{background: transparent; border: 0 none; margin-top: 22px; border-top: 1px solid #B4B4B4; padding: 0; padding-top: 30px;
    .p-button{width: auto; margin-right: 8px;
      &:last-child{margin-right: 0;}
    }
  }
}
.manager-title-row{
  border-bottom: 1px solid #EEEEEE;
}
.function-view .p-toolbar-group-right {
  flex-basis: 30%;
  justify-content: flex-end;
}
.function-view .p-button-warning.p-button {
  margin: 0 10px;
}
</style>