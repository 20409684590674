<template>
    <form id="frm_action">
      <div class="p-formgrid">
          <div class="p-card-field p-grid">
              <div class="p-field p-col-12">
                  <label class="p-col-fixed txt-left">Loại dịch vụ <span class="span-cus">*</span></label>
                  <div class="p-col">
                      <!-- <label class="p-inputtext p-component p-filled disabled">{{ type_service_name }}</label> -->
                      <v-select :clearable="false" multiple title="Loại dịch vụ" id= "a1" :filterable="false" placeholder="Chọn loại dịch vụ" v-model="selTypeService" :options="listTypeService" label="name" @search="fetchOptionsTypeService" class="p-inputtext p-component p-filled">
                          <span slot="no-options" @click="$refs.select.open = false">
                              Không có dữ liệu
                          </span>
                      </v-select>
                      <input class="input_tmp_validator" id="typeservice_id">
                  </div>
              </div>
              <div class="p-field p-col-12">
                  <label class="p-col-fixed txt-left">Câu hỏi <span class="span-cus">*</span></label>
                  <div class="p-col">
                      <InputText v-model="name" type="text" id="name" placeholder="Câu hỏi"/>
                  </div>
              </div>
              <div class="p-field p-col-12">
                  <label class="p-col-fixed txt-left">Thứ tự <span class="span-cus">*</span></label>
                  <div class="p-col">
                      <InputText v-model="ordinal" type="text" id="ordinal" placeholder="Thứ tự"/>
                  </div>
              </div>
          </div>
      </div>
    </form>
</template>

<script>
import DataServices from '@/core/DataServices'
// import ApiRepository from "@/core/ApiRepository";
// import CkditorCore from '../CkditorCore';
export default {
  // components: {
  //   CkditorCore
  // },
  props: {
    mode: String,
    model: Object,
    users: Array,
    triggerSave: Boolean
  },
  data() {
    return {
      formMode: this.mode,
      trigger_save: this.triggerSave,
      name: '',
      ordinal: 0,
      images: [],
      models: {},
      deleteId: null,
      deleteName: null,
      showWarningDialog: false,
      pagination: true, // true cho phép phân trang
      paginationRows: 10, // số dòng trên 1 trang,
      listData: [],
      // dataModel: null,
      componentKey: 0,
      filters: {},
      icon_service: [],
      maxFileSize: 2097152 , // 2MB   /*104857600, // 100MB*/
      name_en:'',
      plain_name: '',
      description: this.model.description,
      plain_description: '',
      selService: [],
      listService: [],
      listServiceAll: [],
      selTypeService: [],
      listTypeService: [],
      listTypeServiceAll: []
    }
  },
  watch: {
    // 'selTypeService': async function() {
    //     if (this.selTypeService.id != this.type_service_id) {
    //         this.selService = [];
    //     }
    //     this.type_service_id = this.selTypeService.id;
    //     this.type_service_type = this.selTypeService.type;
    //     await this.getListService();
    // },
    'triggerSave'() {
        if (this.triggerSave) {
          this.saveData();
      }
    },
    'name'() {
        this.plain_name = this.$commonFuction.SkipVN(this.name).trim();
    },
    'description'() {
      this.plain_description = this.$commonFuction.replaceHtml(this.$commonFuction.SkipVN(this.description)).trim();
    }
  },
  async mounted () {
    this.reexamine = this.$route.query.reexamine;
    if (this.$route.params.id !== undefined && this.$route.params.id !== null) {
      await this.getData(this.$route.params.id);
    }
    if (!this.$commonFuction.isEmpty(this.model.icon_service)) {
      let url = this.model.icon_service.url
      let split = url.split('/')
      let fileName = split[split.length - 1]
      let obj = {'name': fileName, 'file': '', 'objectURL': url,type:'old_edit'}//type:'old_edit' nếu gặp type này ko update avatar
      this.icon_service.push(obj)
    }
    this.description = this.model.description;
    console.log(this.description);
    if (this.model.name_en) {
      this.name_en = this.model.name_en;
    }
  },
  async created() {
    await this.getListTypeService();
  },
  methods: {
    getField(col) {
      return col.key;
    },
    getLabel(col) {
      if (col.label) return col.label;
      else return col.name;
    },
    getHeaderStyleView() {
      var style = "display : none";
      return style;
    },
    getDataRows(col, row) {
      var value = '';
      if (col.type == "Text" && row[col.key] && row[col.key].length > 100) {
        var result = row[col.key].substr(0, row[col.key].lastIndexOf(" ", 100)) + "...";
        value = result;
      }
      return value
    },
    clickEdit() {
      this.$router.push({ path: '/admin/list-question/edit/' + this.models.id, params: {id: this.models.id}});
    },
    backToList() {
      this.$router.push({ path: '/admin/list-question' });
    },
    clickDelete() {
      this.deleteId = this.models.id
      this.deleteName = this.models.fullname
      this.showDeleteDialog = true
    },
    async getListTypeService(){

      var where_key = {
        deleted : {_eq : false}
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('type_service',{"fields" : `id name type`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_type_service = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_type_service.push({id : e.id, name :e.name, type: e.type});
      }
      this.listTypeService = list_type_service;
      this.listTypeServiceAll = list_type_service;
    },
    async fetchOptionsTypeService(search, loading){
      console.log(loading);
      console.log('search',search);
      if(search !== '' && search !== null){
        var where_key = {
          deleted : {_eq : false},
          plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('type_service',{"fields" : `id name type`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_type_service = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_type_service.push({id : e.id, name :e.name, type: e.type});
        }
        this.listTypeService = list_type_service;
      }else{
        // cosnole.log("dasdasd")
        this.listTypeService = this.listTypeServiceAll
      }
    },
    
    async validateData() {
        this.validationErrors = {};
        
        let name_add = document.getElementById("name");
        if (this.name == undefined || this.name == null || this.name == ''){
            name_add.setCustomValidity("Câu hỏi không được để trống!");
        }else {
            name_add.setCustomValidity("");
        }
        
        let type_service_add = document.getElementById("typeservice_id");
        if (this.selTypeService == undefined || this.selTypeService == null || this.selTypeService == '' || this.selTypeService.length <= 0){
            type_service_add.setCustomValidity("Loại dịch vụ không được để trống!");
        }else {
            type_service_add.setCustomValidity("");
        }
        let ordinal_add = document.getElementById("ordinal");
        if (this.ordinal == undefined || this.ordinal == null || this.ordinal == ''){
            ordinal_add.setCustomValidity("Thứ tự không được để trống!");
        }else {
            if (!this.$commonFuction.isNumber(this.ordinal.toString())) {
              ordinal_add.setCustomValidity("Thứ tự không đúng định dạng!");
            } else {
              ordinal_add.setCustomValidity("");
            }
        }

        var inpObj = document.getElementById("frm_action");
        if (!inpObj.checkValidity()){
            console.log('test=====================');
            inpObj.reportValidity();
            return true;
        }
    },
    onFileClear(index,key) {
      if(key=='icon_service'){
        this.icon_service.splice(index, 1);
        document.getElementById('file_name').innerText = "";
        console.log(document.getElementById('images_service'));
        document.getElementById('images_service').value = null;
      }
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    async onFileSelectLogo(event) {
      let files = event.target.files || event.dataTransfer.files;
      if(files.length > 0) {
        let acceptTags = ['jpeg', 'jpg', 'gif', 'png', 'JPEG', 'JPG', 'PNG', 'GIF', 'svg', 'SVG']
        for (let i = 0; i < files.length; i++) {
          if (!acceptTags.includes(files[i].name.split('.').pop())) {
            alert("Chỉ hỗ trợ đăng tải các định dạng: jpeg, jpg, gif, png, svg");
            return;
          }
          document.getElementById('file_name').innerText = files[i].name;
        }
        var arrTemp = [], arrFiles = [];
        for (let file of files) {
          if (this.isImage(file) && file.size <= this.maxFileSize) {
            file.source = file;
            file.objectURL = window.URL.createObjectURL(file);
            arrTemp.push(file);
          }
          if (file.size > this.maxFileSize) arrFiles.push(file);
        }
        if (arrFiles.length > 0) {
          alert("Hình ảnh của bạn lớn hơn 2MB");
        } else {
          // if (this.icon_service.length > 0 && this.icon_service[0].file === '') {
          //   this.imagesDeletes.push(this.icon_service[0])
          // }
          this.icon_service = [];
          this.icon_service = this.icon_service.concat.apply(this.icon_service, arrTemp);
        }
      }
    },
    handleDataCkditor: function(e,key) {
      var dataContent = (e.length) ? e[0]:'';
      console.log(dataContent);
      if (key == 'description'){
        this.description = dataContent;
      }
    },
    async saveData() {
      this.$commonFuction.is_loading(true);
      this.$emit('update:triggerSave', false);
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
      
      if(Object.entries(this.validationErrors).length === 0){
        this.$commonFuction.is_loading(true);
        this.showWarningDialog = false;
        var params = {
            name: this.name,
            plain_name: this.$commonFuction.SkipVN(this.name).trim(),
            ordinal: this.ordinal,
            services: this.selTypeService
        }
        console.log("params",params)
        this.$emit('save', params);
      }else{
        this.$commonFuction.is_loading(false);
      }
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('questions', {"fields" : "id name ordinal question_services {type_service {id name type}}"}),
        variables: {
          where_key: {
            id: {'_eq': id},
            deleted: {_eq: false}
          }
        },
        fetchPolicy: "network-only"
      })
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
        this.name = this.models.name;
        this.ordinal = this.models.ordinal;
        for(let i =0; i < this.models.question_services.length; i++) {
          this.selTypeService.push({id: this.models.question_services[i].type_service.id, name: this.models.question_services[i].type_service.name})
        }
        this.componentKey++;
      }
    }
  },
  apollo: {
  },
  
}
</script>
<style lang="scss">
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.view-action{
  .toolbar-top{background: #fff; padding: 0; border: 0 none;margin-bottom: 16px; padding-bottom: 10px; border-bottom: 1px solid #EEEEEE;
    .manager-title{border: 0 none; padding: 0; margin: 0;}
    .p-toolbar-group-right {
      .p-button{margin-right: 8px; width: auto;
        &:last-child{margin-right: 0;}
      }
    }
  }
  .p-tabview{
    .p-tabview-panels{ padding: 0;}
    .p-tabview-nav{
      li{margin-right: 8px;
        .p-tabview-nav-link{border: 1px solid #B4B4B4; border-radius: 5px 5px 0px 0px !important; background: #eee; font-size: 15px;
          &:hover{text-decoration: none;}
        }
        &.p-highlight{
          .p-tabview-nav-link{background: #00A8FF !important;border: 1px solid #B4B4B4; border-radius: 5px;}
        }
        &:hover{
          .p-tabview-nav-link{background: #00A8FF !important; color: #fff !important;}
        }
      }
    }
  }
  .p-formgrid { margin: 0;
    .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
    .p-card-field{ padding: 24px;
      .p-field{
        .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
        .p-inputtext p-component p-filled{width:500px !important; border-radius: 5px;
          &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px; color: #707070; height: 40px;}
          &.rows-4{height: 80px;}
          .file-select{margin: 0;
            input[type="file"]{display: none;}
            .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
          }
        }
        .box-images{ margin-top: 10px; position: relative;
          .item-img{height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative; border: 0.5px solid #ccc;
            &.p-col-5{width: 18%;;}
            img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain;}
            .button-clear{width: 18px; height: 18px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
              img{vertical-align: top;}
            }
          }
        }
      }
    }
  }
  .p-rates{
    .top-rates{ padding: 16px;color: #000;border-bottom: 1px solid #B4B4B4; 
      label{margin: 0;}
      span{font-weight: 600; padding-left: 100px;}
    }
    .list-rates{padding: 16px;}
  }
  .table-view >.p-datatable .p-datatable-tbody > tr > td {
    color: #707070 !important;
  }
  .p-inputtext {
    min-height: 40px;
  }
  .p-formgrid .p-card-field .p-field .p-col-width {
    min-height: 40px;
    width: 100% !important;
  }
}

.vs__dropdown-option.vs__dropdown-option--selected,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight{
  background-color: #142053 !important;
  color: #ffffff;
}

.span-cus{
  color : red !important;
}
</style>